import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Header from "../components/header";
import Seo from "../components/seo";

const Confirmation = ({ data }) => {
  return (
    <Layout>
      <Seo title="Hemos recibido tu mensaje" />
      <Header />
      <main style={{ justifyContent: "center" }}>
        <div className="description" style={{ textAlign: "center" }}>
          <h1 style={{ color: "#42b588", lineHeight: "50px" }}>
            Hemos recibido correctamente tu mensaje
          </h1>
          <p>
            Si lo deseas, puedes volver a contactar con nosotros desde la página
            de <Link to="/contacto">contacto</Link>
          </p>
          <div className="contact-containter" />
        </div>
      </main>
    </Layout>
  );
};

export default Confirmation;
